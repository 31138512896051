<script setup>
const nuxtApp = useNuxtApp()

const staticURLAdimg = useRuntimeConfig().public.staticURLAdimg
const staticURLImg = useRuntimeConfig().public.staticURLImg

const props = defineProps({
    spot: {
        type: String,
        default: null,
    },
    page: {
        type: String,
        default: null,
    },
    status: {
        type: String,
        default: 'Paid',
    },
    position: {
        type: String,
        default: null,
    },
})

const { data, status, error } = await useLazyAsyncData(
    `${props.spot}-${props.page}-${props.position}`,
    () => $fetch(`/api/campaign/bookings?spot=${props.spot}&page=${props.page}&status=${props.status}`),
    {
        transform: (data) => {
            return {
                spotlight: data.bookings?.edges.map((edge) => edge.node),
                fetchedAt: new Date(Date.now()),
            }
        },
        getCachedData(key) {
            const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key]

            if (!data) {
                return
            }

            const expirationDate = new Date(data.fetchedAt)
            expirationDate.setTime(expirationDate.getTime() + 60 * 60000) // cache set to 1 hour
            const isExpired = expirationDate.getTime() < Date.now()
            if (isExpired) {
                return
            }

            return data
        },
    }
)

watchEffect(() => {
    if (error.value) {
        throw createError({
            statusCode: 500,
            message: error.value,
            fatal: false,
        })
    }
})

// Image source set
const imgSources = computed(() => {
    return JSON.parse(data.value?.spotlight[0]?.campaign?.content).imgSources
})
</script>

<template>
    <v-row id="spotlight" no-gutters>
        <v-col v-if="status === 'pending' || status === 'idle'"><app-loader /></v-col>
        <v-col v-else-if="data.spotlight.length > 0">
            <a
                :id="data.spotlight[0].campaign.urlId"
                :data-adspot="`${data.spotlight[0].spot.name}-${data.spotlight[0].spot.page.name}`"
                :data-adbrand="data.spotlight[0].brand.name"
                :data-adtitle="data.spotlight[0].campaign.title"
                :href="data.spotlight[0].campaign.url"
                :aria-label="`Explore ${data.spotlight[0].campaign.title}`"
                :class="data.spotlight[0].campaign.gtag"
                rel="noopener"
                :target="data.spotlight[0].campaign.isInternal ? undefined : `_blank`"
            >
                <v-img
                    id="cardImg"
                    :src="
                        data.spotlight[0].campaign.isInternal
                            ? staticURLImg + data.spotlight[0].campaign.img
                            : staticURLAdimg + data.spotlight[0].campaign.img
                    "
                    :alt="data.spotlight[0].campaign.title"
                    class="fade-in-left rounded"
                >
                    <template #sources>
                        <source
                            v-for="imgSrc in imgSources"
                            :key="imgSrc.id"
                            :media="imgSrc.media"
                            :srcset="staticURLAdimg + imgSrc.img"
                        />
                        <!-- <source media="(max-width: 800px)" :srcset="`${staticURLAdimg}afwv-blkfday-signup-sm.webp`" />
                        <source media="(max-width: 1280px)" :srcset="`${staticURLAdimg}afwv-blkfday-signup-md.webp`" />
                        <source media="(max-width: 1920px)" :srcset="`${staticURLAdimg}afwv-blkfday-signup-lg.webp`" />
                        <source media="(min-width: 1920px)" :srcset="`${staticURLAdimg}afwv-blkfday-signup-xl.webp`" /> -->
                    </template>
                </v-img>
            </a>
            <img
                v-if="data.spotlight[0].campaign.trkngPxl"
                :style="`${data.spotlight[0].campaign.trkngPxlStyle}`"
                :src="data.spotlight[0].campaign.trkngPxl"
                :width="data.spotlight[0].campaign.trkngPxlWidth"
                :height="data.spotlight[0].campaign.trkngPxlHeight"
                :alt="data.spotlight[0].campaign.title"
                border="0"
            />
        </v-col>
        <v-col v-else>
            <nuxt-link
                :data-adspot="`${props.spot}-${props.page}`"
                data-adbrand="satYatunes"
                data-adtitle="Spot open for booking"
                to="support"
                aria-label="Contact us to book this spot"
                class="bnrclk"
            >
                <v-img
                    id="cardImg"
                    src="/wbst-spotlight-blank-lg.webp"
                    alt="Spot open for booking"
                    class="fade-in-left"
                >
                    <template #sources>
                        <source media="(max-width: 800px)" srcset="/wbst-spotlight-blank-sm.webp" />
                        <source media="(max-width: 1280px)" srcset="/wbst-spotlight-blank-md.webp" />
                        <source media="(max-width: 1920px)" srcset="/wbst-spotlight-blank-lg.webp" />
                        <source media="(min-width: 1920px)" srcset="/wbst-spotlight-blank-xl.webp" />
                    </template>
                </v-img>
            </nuxt-link>
        </v-col>
    </v-row>
</template>

<style scoped>
/*.clipped {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 86%, 50% 100%, 0 86%);
    clip-path: polygon(0 0, 100% 0, 100% 86%, 50% 100%, 0 86%);
}
.bg-grad-anim-spotlight {
    background: linear-gradient(
        300deg,
        v-bind('spotlight.color1'),
        v-bind('spotlight.color2'),
        v-bind('spotlight.color1')
    );
    background-size: 180% 180%;
    animation: gradient-animation 15s ease infinite;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}*/
</style>
